<div class="main-container">
  <mat-card *ngIf="!passwordChanged" class="main-card" fxFlex="400px" fxFlex.xs="100%" fxFlex.sm="50%">
      <mat-card-header class="main-card-header">
          <mat-card-title>Reset your password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <form class="main-card" [formGroup]="form" (ngSubmit)="save()">


              <mat-form-field appearance="outline" class="width-full margin-top-5">
                  <mat-label>Password</mat-label>
                  <input matInput [type]="hidePassword ? 'password' : 'text'" name="password"
                    formControlName="password" required>
                  <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="hidePassword">
                      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
              </mat-form-field>
              <mat-error *ngIf="form.controls.password.hasError('required') && form.controls.password.touched">
                  <span>Password is required</span>
              </mat-error>
              <mat-error *ngIf="form.controls.password.hasError('minlength') && form.controls.password.touched">Password must have at least {{minPassword}} characters</mat-error>
              <div class="main-card-actions">
                  <button class="border-radius" [disabled]="!form.valid" mat-raised-button color="primary" type="submit">Save</button>
              </div>
          </form>
      </mat-card-content>
  </mat-card>

  <mat-card *ngIf="passwordChanged" class="main-card" fxFlex="500px" fxFlex.xs="100%" fxFlex.sm="50%">
    <mat-card-header class="main-card-info-action">
        <mat-card-title>Your password was successfully changed</mat-card-title>
    </mat-card-header>
</mat-card>
</div>
