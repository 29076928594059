import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component';
import {
    AngularFireAuthGuard,
    redirectLoggedInTo,
} from '@angular/fire/auth-guard';

const redirectLoggedInToMain = () => redirectLoggedInTo(['']);

const routes: Routes = [
    {
        path: 'signin',
        component: SigninComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToMain }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
