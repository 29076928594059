import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SnackBarService } from '@shared/services/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
      private snackBarService: SnackBarService
  ) {}

  handleError = (error: HttpErrorResponse): Observable<never> => {

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(error);
    }

    this.snackBarService.error(error.message);
    // Return an observable with a user-facing error message.
    return throwError(error.message);
  }
}
