import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from '@shared/models/snackBarData.interface';
import { SnackBarType } from '@shared/models/snackBarType.enum';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {

  message: string;
  snackBarType = SnackBarType;

  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
      private snackBarRef: MatSnackBar
  ) {}

  dismiss(): void {
    this.snackBarRef.dismiss();
  }

}
