<mat-toolbar color="accent">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col space-between">
                    <span>View Players</span>
                    <button mat-raised-button color="primary" class="border-radius" (click)="signOut()">Sign Out</button>
                </div>
            </div>
        </div>
    </div>
</mat-toolbar>

<div class="container padding-top-25">
    <div class="row">
        <div class="col space-between search">
            <h3>{{ resultCounter }} Results</h3>
            <form [formGroup]="filterForm" class="filter-form">
                <mat-form-field class="search-input" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" name="search" formControlName="email">
                </mat-form-field>
            </form>
        </div>
    </div>
</div>
<div class="container padding-bottom-25">
    <div class="row">
        <div class="col padding-bottom-15">
            <button mat-raised-button color="accent" class="border-radius float-right" (click)="refresh()">Refresh <mat-icon [ngClass]="{'spin': refreshing}">autorenew</mat-icon></button>
        </div>
        <div class="col mat-elevation-z8 border-radius">
            <table [dataSource]="dataSource" mat-table matSort multiTemplateDataRows class="mat-elevation-z8 width-full">
                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="email-row">
                                <span class="email-row-text">{{element.email}}</span>
                            <button mat-icon-button color="warn" aria-label="Delete user" matTooltip="Delete user" matTooltipPosition="before" (click)="openDeleteConfirmDialog(element.uid)">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        No records found.
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        Loading, please wait...
                    </mat-footer-cell>
                </ng-container>

                <mat-footer-row *matFooterRowDef="['noData']" [style.display]="dataSource && dataSource.filteredData.length === 0 ? 'flex' : 'none'"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['loading']" [style.display]="dataSource === undefined ? 'flex' : 'none'"></mat-footer-row>

            </table>
            <mat-paginator #paginator
                           [length]="dataSource?.data ? dataSource.data.length : 0"
                           [pageIndex]="pagination.pageIndex"
                           [pageSizeOptions]="pagination.pageSizeOptions"
                           [pageSize]="pagination.pageSize"></mat-paginator>
        </div>
    </div>
</div>
