import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { AuthenticationService, ProgressBarService, SnackBarService } from '@shared';

@Injectable({
    providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private snackBarService: SnackBarService,
        private progressBarService: ProgressBarService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.progressBarService.setProgress(true);
        return this.authService.getUser().pipe(
            concatMap(user => this.authService.checkIfAdmin(user.uid).pipe(
                tap(result => {
                    if (!result) {
                        this.authService.signOut().subscribe(() => {
                            this.progressBarService.setProgress(false);
                            this.snackBarService.error(`Permission denied`);
                            this.router.navigate(['signin']);
                        });
                    }
                })
            )),
        );
    }
}
