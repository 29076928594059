import { Component } from '@angular/core';
import { AuthenticationService, ProgressBarService } from '@shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
      public progressBar: ProgressBarService,
      private authService: AuthenticationService
  ) {
    this.authService.getUser().subscribe();
  }
}
