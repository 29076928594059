import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Player } from '../../models/player.interface';
import { AuthenticationService, ConfirmDialogComponent, ConfirmDialogData, ProgressBarService, SnackBarService } from '@shared';
import { FirebaseService, UntilDestroy, untilDestroyed } from '@core';
import { Router } from '@angular/router';
import { finalize, concatMap, first, tap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PlayersService } from '../../services/players.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

const PaginatorOptions = {
    PAGE_SIZE: 10,
    PAGE_INDEX: 0,
    PAGE_SIZE_OPTIONS: [10, 20, 50]
} as const;

const MODAL_WINDOW_WIDTH = '350px';

@UntilDestroy()
@Component({
    selector: 'app-players',
    templateUrl: './players.component.html',
    styleUrls: ['./players.component.scss']
})
export class PlayersComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<Player>;
    resultCounter = 0;
    pagination = {
        pageSize: PaginatorOptions.PAGE_SIZE,
        pageIndex: PaginatorOptions.PAGE_INDEX,
        pageSizeOptions: PaginatorOptions.PAGE_SIZE_OPTIONS,
    };
    displayedColumns: string[] = ['email'];
    dataSource: MatTableDataSource<Player>;
    filterForm: FormGroup;
    refreshing = false;

    constructor(
        private dialog: MatDialog,
        private authService: AuthenticationService,
        private progressBarService: ProgressBarService,
        private router: Router,
        private playersService: PlayersService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.initFilterForm();
        this.getAllPlayers().subscribe();
        this.initFiltering();
    }

    openDeleteConfirmDialog(uid: string): void {
        const confirmDialogData: ConfirmDialogData = {
            title: 'Delete User',
            body: 'Please confirm if you would like to <b>Delete</b> this user?',
            yesBtnText: 'Yes, Delete',
            noBtnText: 'Cancel'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: MODAL_WINDOW_WIDTH,
            data: confirmDialogData
        });

        dialogRef.afterClosed().pipe(
            untilDestroyed(this)
        ).subscribe(result => {
            if (result) {
                this.deletePlayer(uid);
            }
        });
    }

    signOut(): void {
        this.progressBarService.setProgress(true);
        this.authService.signOut().pipe(
            finalize(() => {
                this.progressBarService.setProgress(false);
            }),
            untilDestroyed(this)
        ).subscribe(() => {
            this.router.navigate(['/signin'], { replaceUrl: true });
        });
    }

    getAllPlayers(): Observable<Player[]> {
        return this.getUser().pipe(
            concatMap(user => this.playersService.getAllPlayers(user.uid).pipe(
                finalize(() => {
                    this.progressBarService.setProgress(false);
                })
            )),
            tap(players => {
                this.initDataSourceTable(players);
                this.countResults(players);
            }),
            finalize(() => this.progressBarService.setProgress(false)),
            untilDestroyed(this)
        );
    }

    refresh(): void {
        this.refreshing = true;
        this.progressBarService.setProgress(true);
        this.getAllPlayers().subscribe(() => {
            this.applyFilter();
            this.refreshing = false;
        });
    }

    private initFiltering(): void {
        this.filterForm.valueChanges.pipe(
            debounceTime(300)
        ).subscribe(() => {
            this.applyFilter();
        });
    }

    private applyFilter(): void {
        const emailField = this.filterForm.value.email;
        const email = emailField === null ? '' : emailField;
        this.dataSource.filter = email.toLowerCase();
        this.countResults(this.dataSource.filteredData);
    }

    private initFilterForm(): void {
        this.filterForm = this.formBuilder.group({
            email: new FormControl('')
        });
    }

    private initDataSourceTable(players: Player[]): void {
        this.dataSource = new MatTableDataSource(players);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
    }

    private countResults(players: Player[]): void {
        this.resultCounter = players.length;
    }

    private getUser(): Observable<{ uid: string }> {
        return this.authService.user$.pipe(
            first(),
            untilDestroyed(this),
        );
    }

    private deletePlayer(playerUid: string): void {
        this.progressBarService.setProgress(true);
        this.getUser().pipe(
            concatMap(user => this.playersService.deletePlayer(playerUid, user.uid).pipe(
                finalize(() => {
                    this.progressBarService.setProgress(false);
                }),
                untilDestroyed(this)
            ))
        ).subscribe(() => {
            this.dataSource.data = this.dataSource.data.filter(user => user.uid !== playerUid);
            this.countResults(this.dataSource.data);
            this.snackBarService.success('User successfully deleted');
        });
    }
}
