import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { EmailActionRoutingModule } from './email-action-routing.module';
import { EmailActionComponent } from './components/email-action/email-action.component';
import { SharedModule } from '@shared';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { InvalidActionComponent } from './components/invalid-action/invalid-action.component';


@NgModule({
    declarations: [EmailActionComponent, ResetPasswordComponent, InvalidActionComponent],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        FlexLayoutModule,
        EmailActionRoutingModule
    ],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class EmailActionModule {
}
