import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService, UntilDestroy, untilDestroyed } from '@core';
import { ProgressBarService } from '@shared';
import { mergeMap } from 'rxjs/operators';
import { EmailActionService } from '../../services/email-action.service';

@UntilDestroy()
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

    minPassword = 6;

    form: FormGroup = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(this.minPassword)]]
    });

    hidePassword = true;
    passwordChanged = false;


    constructor(
        private formBuilder: FormBuilder,
        private firebase: FirebaseService,
        private route: ActivatedRoute,
        private progressBar: ProgressBarService,
        private emailActionService: EmailActionService
    ) {}

    save(): void {
        this.progressBar.setProgress(true);
        this.route.queryParamMap.pipe(
            mergeMap(params => {
                const code = params.get('oobCode');
                const password = this.form.value.password;
                return this.firebase.confirmPasswordReset(code, password);
            }),
            untilDestroyed(this))
            .subscribe(() => {
                this.progressBar.setProgress(false);
                this.passwordChanged = true;
            }, error => {
                this.emailActionService.showInvalidAction.next(true);
                this.progressBar.setProgress(false);
                console.error(error);
            });
    }

}
