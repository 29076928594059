import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailActionMode } from '@shared/models/emailActionMode.enum';
import { UntilDestroy, untilDestroyed } from '@core';
import { first } from 'rxjs/operators';
import { EmailActionService } from '../../services/email-action.service';

@UntilDestroy()
@Component({
    selector: 'app-email-action',
    templateUrl: './email-action.component.html',
    styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {

    mode: EmailActionMode;
    showInvalidAction = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private emailActionService: EmailActionService
    ) {
    }

    ngOnInit(): void {
        this.chooseRoute();
        this.emailActionService.showInvalidAction.pipe(
            untilDestroyed(this)
        ).subscribe((res) => {
            this.showInvalidAction = res;
        });
    }

    chooseRoute(): void {
        this.route.queryParamMap.pipe(
            first(),
            untilDestroyed(this)
        ).subscribe(params => {
            const mode = params.get('mode');
            const code = params.get('oobCode');
            if (mode === EmailActionMode.ResetPassword) {
                this.mode = mode as EmailActionMode;
            } else {
                this.showInvalidAction = true;
            }
        });
    }

}
