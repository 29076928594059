export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyCn23EeEVsnK7COwJYVAzMxloq5wxBhpko',
        authDomain: 'diversification-dev.firebaseapp.com',
        projectId: 'diversification-dev',
        storageBucket: 'diversification-dev.appspot.com',
        messagingSenderId: '1046841651750',
        appId: '1:1046841651750:web:dec62ca699b7ec5ea0ace7',
        measurementId: 'G-2Z581Z4ETE'
    }
};
