import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlayersRoutingModule } from './players-routing.module';
import { MaterialModule } from '../material/material.module';
import { PlayersComponent } from './components/players/players.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '@shared';

@NgModule({
    declarations: [PlayersComponent],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        FlexLayoutModule,
        PlayersRoutingModule
    ]
})
export class PlayersModule {
}
