
<div class="confirm-dialog-header">
    <h1 mat-dialog-title>{{ data?.title }}</h1>
</div>
<div class="confirm-dialog-content" mat-dialog-content>
    <div [innerHTML]="data?.body" ></div>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button (click)="onNoClick()">{{ data?.noBtnText }}</button>
    <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ data?.yesBtnText }}</button>
</div>
