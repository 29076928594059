import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '@shared';
import { Player } from '../models/player.interface';
import { FirebaseService } from '@core';

@Injectable({
    providedIn: 'root'
})
export class PlayersService {

    constructor(
        private errorHandler: ErrorHandlerService,
        private firebaseService: FirebaseService
    ) {
    }

    getAllPlayers(uid: string): Observable<Player[]> {
        return this.firebaseService.callFunction<Player[]>('getAllUsers', { adminId: uid }).pipe(
            catchError(this.errorHandler.handleError)
        );
    }

    deletePlayer(playerUid: string, adminUid: string): Observable<void> {
        return this.firebaseService.callFunction<void>('deleteUser', { userUid: playerUid, adminUid }).pipe(
            catchError(this.errorHandler.handleError)
        );
    }
}
