import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { SnackBarType } from '@shared/models/snackBarType.enum';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    private defaultDuration = 7000;
    private defaultActionText = 'Close';

    constructor(
        private snackBar: MatSnackBar,
        private zone: NgZone,
    ) {
    }

    private open(message: string, action: string, duration: number, type: SnackBarType): void {
        this.zone.run(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {
                data: {
                    message,
                    type
                },
                duration
            });
        });
    }

    error(
        message: string,
        action = this.defaultActionText,
        duration = this.defaultDuration
    ): void {
        this.open(message, action, duration, SnackBarType.Error);
    }

  success(
      message: string,
      action = this.defaultActionText,
      duration = this.defaultDuration
  ): void {
    this.open(message, action, duration, SnackBarType.Success);
  }

  info(
      message: string,
      action = this.defaultActionText,
      duration = this.defaultDuration
  ): void {
    this.open(message, action, duration, SnackBarType.Info);
  }
}
