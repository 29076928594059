import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SigninContext } from '../../auth/models/signinContext.interface';

import firebase from 'firebase/app';
import 'firebase/auth';
import { ErrorHandlerService } from '@shared/services/error-handler.service';
import { FirebaseService } from '@core';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
  user$: Observable<firebase.User>;

  constructor(
      private errorHandler: ErrorHandlerService,
      private firebaseService: FirebaseService
  ) {
    this.user$ = this.userSubject.asObservable();
  }

  signIn(context: SigninContext): Observable<firebase.auth.UserCredential> {
    return this.firebaseService.signInWithEmailAndPassword(context.email, context.password).pipe(
        catchError(this.errorHandler.handleError)
    );
  }

  signOut(): Observable<void> {
    return this.firebaseService.signOut().pipe(
        catchError(this.errorHandler.handleError)
    );
  }

  checkIfAdmin(uid: string): Observable<boolean> {
    return this.firebaseService.callFunction<{admin: boolean}>('checkIfAdmin', {adminId: uid}).pipe(
        map(result => result.admin)
    );
  }

  getUser(): Observable<firebase.User> {
    return this.firebaseService.user().pipe(
      tap(user => {
        this.userSubject.next(user);
      })
    );
  }
}
