import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlayersComponent } from './components/players/players.component';

import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { AuthAdminGuard } from '@shared/guards/auth-admin.guard';

const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['signin']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'players',
    pathMatch: 'full'
  },
  {
    path: 'players',
    component: PlayersComponent,
    canActivate: [AngularFireAuthGuard, AuthAdminGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlayersRoutingModule { }
