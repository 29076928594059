<div class="main-container">
    <mat-card class="main-card" fxFlex="400px" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card-header class="main-card-header">
            <mat-card-title>Sign In</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form class="main-card" [formGroup]="form" (ngSubmit)="signIn()">
                <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" name="email" formControlName="email">
                </mat-form-field>
                <mat-error *ngIf="form.controls.email.invalid && form.controls.email.touched">
                    <span>Email is required</span>
                </mat-error>

                <mat-form-field appearance="outline" class="width-full margin-top-5">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" name="password"
                           formControlName="password">
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-error *ngIf="form.controls.password.invalid && form.controls.password.touched">
                    <span>Password is required</span>
                </mat-error>

                <div class="main-card-actions">
                    <button class="border-radius" [disabled]="!form.valid" mat-raised-button color="primary" type="submit">Go</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
