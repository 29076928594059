import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private inProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inProgress$: Observable<boolean> = this.inProgressSubject.asObservable();

  setProgress(value: boolean): void {
    this.inProgressSubject.next(value);
  }
}
