import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { from, Observable } from 'rxjs';

import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(
        private angularFireAuth: AngularFireAuth,
        private angularFireFunctions: AngularFireFunctions,
    ) {}

    confirmPasswordReset(code: string, newPassword: string): Observable<void> {
        return from(this.angularFireAuth.confirmPasswordReset(code, newPassword));
    }

    checkActionCode(code: string): Observable<firebase.auth.ActionCodeInfo> {
        return from(this.angularFireAuth.checkActionCode(code));
    }

    callFunction<T>(name: string, data: any): Observable<T> {
        const callable = this.angularFireFunctions.httpsCallable(name);
        return callable(data);
    }

    signInWithEmailAndPassword(email: string, password: string): Observable<firebase.auth.UserCredential> {
        return from(this.angularFireAuth.signInWithEmailAndPassword(email, password));
    }

    signOut(): Observable<void> {
        return from(this.angularFireAuth.signOut());
    }

    user(): Observable<firebase.User> {
        return this.angularFireAuth.user;
    }
}
