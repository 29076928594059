import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@core';
import { AuthenticationService, ProgressBarService } from '@shared';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  hidePassword = true;

  form: FormGroup;

  constructor(
      private progressBarService: ProgressBarService,
      private authService: AuthenticationService,
      private formBuilder: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
  ) {
    this.progressBarService.setProgress(false);
  }

  ngOnInit(): void {
    this.initForm();
  }

  signIn(): void {
    this.progressBarService.setProgress(true);
    this.authService.signIn(this.form.value).pipe(
        finalize(() => {
          this.progressBarService.setProgress(false);
        }),
        untilDestroyed(this)
    ).subscribe(user => {
      this.router.navigate([this.route.snapshot.queryParams.redirect ||  '/'], { replaceUrl: true });
    });
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
